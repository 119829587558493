import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/layout/Layout'
import Header from '../components/Header'
import Lineup from 'gatsby-theme-psg/src/components/event/lineup/Lineup'
import Lead from '../components/Lead';

export const LineupPageTemplate = ({ title, content }) => {
  return (
    <React.Fragment>
      <Header background='rgba(255,120,0,0.85)'>
        <Lead title={title} content={content}/>
      </Header>
      <div className="container my-5 py-5">
        <Lineup />
      </div>
    </React.Fragment>
  )
}

const LineupPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
      <LineupPageTemplate title={post.frontmatter.title} content={post.html} />
    </Layout>
  )
}

export default LineupPage

export const pageQuery = graphql`
  query LineupPage {
    markdownRemark(frontmatter: { templateKey: { eq: "lineup-page" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
